.home-container {
  width: 100%;
  
  .home-header-container {
    padding: 0 100px;
    
    .content-header-title {
      padding: 0 80px;
    }
  }
  
  .home-group-container {
    padding: 55px 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .home-group {
      text-align: center;
      cursor: pointer;
      .home-group-image {
        height: 60px;
      }
      .home-group-name {
        line-height: 50px;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  
  .home-cat2-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 60px 60px;
    cursor: pointer;
    margin-bottom: 50px;
    
    .home-cat2 {
      width: 280px;
      height: 280px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      .home-cat2-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  
  .home-product-group-container {
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    
    .home-product-group-title {
      color: #f2802e;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      
      .swiper-grid-column > .swiper-wrapper{
        flex-direction: unset !important;
      }     
    }
  }
  
  .home-img-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 100px 100px;
    gap: 200px 0;
    
    .home-img-item-container {
      width: 600px;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        object-fit: cover;
      }
    }
  }
  
  .home-whats-news-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
