.product-detail-mobile-container {
  width: 100%;
  padding: 0 16px;

  .product-detail-top-container {
    padding: 30px 0;

    .product-detail-top-title {
      display: flex;
      flex-direction: column;
      gap: 9px;
      margin-bottom: 35px;

      span {
        color: #5C8692;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 154.5%; /* 23.175px */
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      div {
        color: #141414;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 58.5px */
        letter-spacing: -0.45px;
      }
    }

    .product-detail-top-info-container {
      display: flex;
      padding-top: 20px;
      gap: 35px;
      flex-direction: column;
      width: 100%;
      justify-content: center;

      .product-detail-top-info-left-container {
        display: flex;
        width: 100%;
        //height: 496px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .product-detail-top-info-left-img-container-top {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: relative;

          img {
            height: 400px !important;
            width: 100%;
          }
          .product-item-promotion-icon-container {
            position: absolute;
            left: 0;
            top: 0;
            width: 80px;
            height: 80px;
            cursor: pointer;
            user-select: none;
            img {
              max-width: 100%;
              max-height: 100%;
              rotate: -30deg;
            }
          }
        }

        .product-detail-top-info-left-product-img-swiper-container {
          width: 100%;
          .product-detail-top-info-left-product-img-swiper-swiper {
            width: 100%;

            .swiper-wrapper {
              height: 140px;
              margin-bottom: 30px;

              .product-detail-top-info-left-img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                position: relative;

               
                img {
                  max-height: 100%;
                  max-width: 100%;
                }
                .detail-swiper-img{
                  width: 100px;
                  height: 100px;
                  border: 1px solid #ddd;
                  border-radius: 5px
                }
                .detail-swiper-img-special{
                  border: 1px solid #ec4e00;
                }
                .product-item-promotion-icon-container {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 80px;
                  height: 80px;
                  cursor: pointer;
                  user-select: none;
                  img {
                    max-width: 100%;
                    max-height: 100%;
                    rotate: -30deg;
                  }
                }
              }
            }

            .swiper-pagination {
              position: relative;
              padding: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              .swiper-pagination-bullet {
                padding: 0;
                margin: 0;
                width: 12px;
                height: 12px;
              }

              .swiper-pagination-bullet-active {
                width: 16px;
                height: 16px;
                background-color: #7B61FF;
              }
            }
          }

          //.product-detail-top-info-left-product-img-swiper-prev {
          //  top: auto;
          //  bottom: 0;
          //}
          //
          //.product-detail-top-info-left-product-img-swiper-next {
          //  top: auto;
          //  bottom: 0;
          //}
        }
        .product-detail-top-info-left-favorite-icon-container {
          position: absolute;
          right: 0;
          top: 0;
          width: 37px;
          height: 33px;
          cursor: pointer;
          user-select: none;
          z-index: 1;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
    .product-detail-top-info-right-container {
      flex: 1;
      display: flex;
      padding: 30px 0;
      flex-direction: column;
      justify-content: center;
      gap: 26px;

      .product-detail-top-info-right-ref-no {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .product-detail-top-info-right-price {
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 72.222% */
        margin-bottom: 6px;
        
        .price {
          font-size: 36px;
        }
        .hot {
          color: #f2802e;
        }
        .price_original {
          margin-left: 10px;
          text-decoration: line-through;
        }
        
        span {
          font-size: 14px;
        }
      }

      .product-detail-top-info-right-product-description-container {
        .product-detail-top-info-right-product-description-title {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 180%; /* 28.8px */
          margin-bottom: -20px;
        }

        .product-detail-top-info-right-product-description-item {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 180%;
          display: flex;
          margin-top: 20px;
          align-items: flex-start;

          //&:before {
          //  content: '·';
          //  font-weight: bold;
          //  display: flex;
          //  align-items: center;
          //  justify-content: center;
          //  width: 16px;
          //  min-width: 16px;
          //}
        }
      }
      .product-detail-top-info-right-product-promotion-container {
        .product-detail-top-info-right-product-promotion-title {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 180%; /* 28.8px */
        }

        .product-detail-top-info-right-product-promotion-item {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 180%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        }
      }

      .product-detail-top-info-right-upcs {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        span {
          font-weight: 400;
        }
      }

      .product-detail-top-info-right-bottom-container {
        display: flex;
        align-items: center;
        gap: 50px;
        justify-content: space-between;

        .product-detail-top-info-right-bottom-left-container {
          display: flex;
          align-items: center;
          gap: 10px;

          .product-detail-top-info-right-bottom-left-label {
            color: #393939;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
          }

          .product-detail-top-info-right-bottom-left-input {
            width: 75px;
            height: 25px;
            border: 1px solid #C8C8C8;
            display: flex;
            align-items: center;
            border-radius: 0;

            .ant-input-number-input-wrap {
              height: 100%;

              .ant-input-number-input {
                height: 100%;
              }
            }
          }
        }

        .product-detail-top-info-right-bottom-btn {
          height: 30px;
          padding: 10px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          background: #133670;

          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          cursor: pointer;
          user-select: none;
        }
        .product-detail-top-info-right-bottom-btn-disabled {
          height: 30px;
          padding: 10px 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          background: #AEAEAE;

          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          cursor: pointer;
          user-select: none;
        }
      }
    } 
  }
  .product-detail-rich-text-description-container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 50px 0 50px;
    .product-detail-rich-text-description-title {
      text-align: center;
      color: #133670;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
    .product-detail-rich-text-description-content {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }
  .product-detail-more-product-container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 50px 0 50px;

    .product-detail-more-product-title {
      color: #133670;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
    }

    .product-detail-more-product-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px 10px;

      .product-detail-more-product-item-container {
        width: 48.5%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
