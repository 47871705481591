.product-item-mobile-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  .product-item-img-container {
    width: 100%;
    height: 40.5vw;
    //height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    .product-item-favorite-icon-container {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 30px;
      cursor: pointer;
      user-select: none;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .product-item-promotion-icon-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px;
      cursor: pointer;
      user-select: none;
      img {
        max-width: 100%;
        max-height: 100%;
        rotate: -30deg;
      }
    }
  }

  .product-item-price {
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 130% */
    height: 26px;
    max-width: 100%;

    span {
      font-size: 20px;
    }
    
    .hot {
      color: #f2802e;
    }
    
    .price_original {
      margin-left: 10px;
      font-size: 14px;
      text-decoration: line-through;
    }
  }

  .product-item-des {
    color: #393939;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 162.5% */
    height: 52px;
    width: 100%;
    white-space: pre-wrap;
  }
  
  .hot {
    color: #f2802e;
  }

  .product-item-btn {
    height: 30px;
    padding: 10px 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #133670;

    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
  .product-item-btn-disabled {
    height: 30px;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #AEAEAE;

    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
  .product-item-type-2-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .product-item-type-2-btn-plus-icon,
    .product-item-type-2-btn-min-icon {
      font-size: 30px;
      color: #133670;
    }

    .product-item-type-2-btn-qty {
      width: 60px;
      text-align: center;
      font-size: 19px;
      font-weight: 400;
      cursor: auto;
    }
  }
}
