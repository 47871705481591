.get-in-touch-mobile-container {
  width: 100%;
  background: rgba(255, 255, 255, 0.92);
  display: flex;
  flex-direction: column;
  align-items: center;

  .get-in-touch-title {
    color: #133670;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .get-in-touch-form-container {
    margin-bottom: 40px;
    width: 100%;

    .ant-form-item {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      .ant-form-item-label {
        padding-bottom: 12px;

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #666666;
        }

        .ant-form-item-required {
          &:after {
            content: '*';
            display: block;
            color: #EE1D52;
          }
        }
      }

      .ant-input {
        height: 50px;
        border: 1px solid rgba(102, 102, 102, 0.35);
        border-radius: 12px;
        font-size: 16px;
      }
    }
  }

  .get-in-touch-btn {
    display: flex;
    height: 50px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 20px;
    background: #133670;

    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
}
