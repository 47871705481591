.o-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 59px;

  .o-success-img-container {
    width: 250px;
    height: 250px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .o-success-content-container {
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    gap: 45px;

    .o-success-content-title-container {
      color: #616161;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
    }

    .o-success-content-des-container {
      color: #f2802e;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-align: center;
    }

    .o-success-content-tip-container {
      color: #616161;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-align: center;
    }
  }

  .o-success-bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .o-success-bottom-btn-container {
      display: flex;
      width: 476px;
      height: 50px;
      padding: 13px 0 14px 0;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      border: 1px solid #111;

      color: #111;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;
    }

    .o-success-bottom-tip-container {
      color: #4B5563;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 142.857% */

      span {
        color: #116ACC;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
