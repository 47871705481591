.contact-us-mobile-container {
  width: 100%;
  padding: 0 16px 50px;

  .contact-us-header-container {
    .content-header-title {
      padding: 0 0px;
    }
  }

  .contact-us-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .contact-us-content-left-container {
      width: 100%;
      margin-bottom: 50px;
    }

    .contact-us-content-right-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .contact-us-content-right-title {
        color: #393939;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }

      .contact-us-content-right-working-hours-container {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .contact-us-content-right-working-hours-item-container {
          color: #000;
          font-weight: 700;

          span {
            font-weight: 400;
          }
        }
      }

      .contact-us-content-right-contact-us-container {
        display: flex;
        flex-direction: column;

        .contact-us-content-right-contact-us-title {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 187.5% */
          padding-top: 25px;
        }

        .contact-us-content-right-contact-us-address {
          color: #133670;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          text-decoration-line: underline;
        }

        .contact-us-content-right-contact-us-des {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
        }
      }

      .contact-us-content-right-social-medias-container {
        display: flex;
        align-items: center;

        .contact-us-content-right-social-medias-list {
          display: flex;
          align-items: center;
          padding: 10px 0;
          gap: 20px;
          margin-left: 50px;

          .contact-us-content-right-social-medias-item-container {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            user-select: none;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }

      .contact-us-content-right-map-container {
        width: 100%;
        height: 568px;
        div {
          div {
            width: calc(100vw - 32px) !important;
            height: 568px !important;
          }
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
}
