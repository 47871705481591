.my-order-item-mobile-container {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #C4C4C4;

  .my-order-item-content {
    padding: 10px 0;

    .my-order-item-row-1,
    .my-order-item-row-2 {
      color: #000;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      display: flex;
      align-items: center;
      height: 32px;
      margin-bottom: 5px;
      flex-direction: row;
      justify-content: space-between;
    }

    .my-order-item-row-1 {
      font-weight: 700;
    }

    .my-order-item-row-2 {
      font-weight: 400;
    }

    .my-order-item-label {
      min-width: 100px;
    }
    .hot {
      color: #f2802e;
    }
  }

  .my-order-item-bottom-container {
    display: flex;
    align-items: center;
    gap: 22px;

    .my-order-item-btn {
      color: #1E4DF2;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      user-select: none;
    }
  }
}
