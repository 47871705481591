* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
}

.spin-root-class {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.overflow-ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.ant-menu-item-selected {
  background-color: #133670 !important;
  .ant-menu-title-content {
    color: white !important;
  }
}
.ant-menu-submenu-open {
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      color: #393939 !important;
      font-weight: 700;
    }
  }
}
.ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    .ant-menu-title-content, .ant-menu-submenu-arrow {
      color: #393939 !important;
    }
  }
}
